body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'SourceHanSerif';
  src: local('SourceHanSerif'), url(./Assets/Fonts/SourceHanSerifCN-Regular-1.otf) format('opentype');
}
@font-face {
  font-family: 'SourceHanSans';
  src: local('SourceHanSans'), url(./Assets/Fonts/SourceHanSansSC-Bold-2.otf) format('opentype');
}